<template>
  <div class="sDashboard">
    <div class="sRunner">
      <div
        v-for="(status, i) in jobStatus"
        :key="'job' + i"
      >
        <div class="sJobContain">
          <div :class="jobStatusClass(status)">
            {{ status.name }}: {{ status.total }}
          </div>
          <div class="sJobList">
            <div v-for="job in jobByStatus(status.code)" :key="job.name" @click="routeJob(job)">
              {{ job.name }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(status, i) in welfareStatus"
        :key="'welfare' + i"
      >
        <div class="sJobContain">
          <div :class="welfareStatusClass(status)">
            Welfare {{ status.name }}: {{ status.total }}
          </div>
          <div class="sJobList">
            <div v-for="welfare in welfareByStatus(status.name)" :key="welfare.projectId + welfare.code" @click="routeWelfare(welfare)">
              {{ welfare.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sCost">
      <graph-highcharts
        v-if="cost"
        :data="costChartData"
      />
    </div>
  </div>
</template>

<script>
import {db} from "@/firestore";
import runnerJob from "@/model/runner/Job";
import dashboardCost from "@/model/dashboard/Cost";
import GraphHighcharts from "@/components/Graph/Highcharts";

export default {
  components: {
    GraphHighcharts,
  },
  data() {
    return {
      cost: null,
      job: [],
      welfareListener: [],
    };
  },
  computed: {
    costChartData() {
      if (!this.cost) return [];
      const dates       = [],
            services    = [],
            serviceCost = {},
            cost        = {};
      for (const row of this.cost) {
        if (row.cost <= 0.01) continue;
        if (dates.indexOf(row.date.value) < 0) {
          dates.push(row.date.value);
        }
        if (services.indexOf(row.description) < 0) {
          services.push(row.description);
        }
        if (!serviceCost[row.description]) {
          serviceCost[row.description] = 0;
        }
        serviceCost[row.description] += row.cost;
        if (!cost[row.date.value]) {
          cost[row.date.value] = {};
        }
        if (!cost[row.date.value][row.description]) {
          cost[row.date.value][row.description] = 0;
        }
        cost[row.date.value][row.description] += row.cost;
      }
      const series = [];
      for (const service of services.sort((a, b) => {
        if(serviceCost[a] > serviceCost[b]) return -1;
        if(serviceCost[a] < serviceCost[b]) return 1;
        return 0;
      })) {
        const s = {
          name: service,
          data: [],
        };
        for (const date of dates.sort()) {
          s.data.push(cost[date] && cost[date][service] ? cost[date][service] : 0);
        }
        series.push(s);
      }

      return {
        chart: {
          type: "area",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "GCP Cost",
        },
        xAxis: {
          categories: dates.sort(),
        },
        yAxis: {
          title: {
            text: "Daily Cost GBP",
          },
          min: 0,
        },
        tooltip: {
          split: true,
        },
        plotOptions: {
          area: {
            stacking: "normal",
            marker: {
              enabled: false,
            },
          },
        },
        series,
      };
    },
    jobStatus() {
      const status = {
        0: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        50: 0,
        60: 0,
        99: 0,
      };
      this.job.filter(j =>
        ![0,10,50,99].includes(j.status)
      ).map(j => {
        status[j.status] += 1;
        return j;
      });
      const activeStatus = [];
      Object.keys(runnerJob.statusCodeList()).map((code) => {
        if (status[code] > 0) {
          activeStatus.push({
            code,
            name: runnerJob.statusCodeList()[code],
            total: status[code],
          });
        }
        return code;
      });
      return activeStatus;
    },
    welfareStatus() {
      const status = {};
      this.welfareListener.filter(w =>
        !['ok'].includes(w.status)
      ).map(w => {
        if (!status[w.status]) {
          status[w.status] = 0;
        }
        status[w.status] += 1;
        return w;
      });
      return Object.entries(status).map(s => ({
        name: s[0],
        total: s[1],
      }));
    },
  },
  created() {
    dashboardCost.getGcpCost().then((cost) => {
      this.cost = cost;
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000 * 60 * 60 * 4);
  },
  methods: {
    jobByStatus(code) {
      const jobList = this.job.filter(j => {
        return j.status === code * 1;
      });
      if (jobList.length > 20) {
        return [...jobList.slice(0, 19), {name: `and ${jobList.length - 19} more`}];
      }
      return jobList;
    },
    jobStatusClass(status) {
      const classes = {sJobHead: true};
      switch (status.code * 1) {
        case 10:
        case 50:
          classes.sSuccess = true;
          break;
        case 11:
          classes.sWarn = true;
          break;
        case 12:
        case 13:
          classes.sError = true;
          break;
        case 0:
        case 14:
        case 60:
        case 99:
        default:
          classes.sInfo = true;
      }
      return classes;
    },
    routeJob(job) {
      this.$router.push({
        name: 'runner-job-detail',
        params: {
          jobID: job.id,
        },
      });
    },
    routeWelfare(welfare) {
      this.$router.push({
        name: 'welfare-project-listener',
        params: {
          listenerID: welfare.id,
        },
      });
    },
    welfareByStatus(status) {
      const welfareList = this.welfareListener.filter(j => {
        return j.status === status;
      });
      if (welfareList.length > 20) {
        return [...welfareList.slice(0, 19), {name: `and ${welfareList.length - 19} more`}];
      }
      return welfareList;
    },
    welfareStatusClass(status) {
      const classes = {sJobHead: true};
      switch (status.name) {
        case 'error':
          classes.sError = true;
          break;
        default:
          classes.sInfo = true;
      }
      return classes;
    },
  },
  firestore: {
    job: db.collection("shepherd-job"),
    welfareListener: db.collection("welfare-listener"),
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/shepherd-vars.scss";

.sDashboard {
  width: 100vw;
  height: 100vh;
  background: $colorGrey1;
  color: antiquewhite;

  .sRunner {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .sJobContain {
      position: relative;
      width: 300px;
      margin: 60px 10px 0;
      border-radius: 4px;
      background-color: #4f4f4f;

      .sJobHead {
        position: absolute;
        left: 10px;
        top: -31px;
        padding: 20px 10px;
        border-radius: 4px;
        box-shadow: 0 12px 20px -10px rgba(33, 150, 243, .28), 0 4px 20px 0 rgba(0, 0, 0, .12), 0 7px 8px -5px rgba(33, 150, 243, .2);

        &.sSuccess {
          background: linear-gradient(60deg, #66bb6a, #43a047) !important;
        }

        &.sWarn {
          background: linear-gradient(60deg, #ffa726, #fb8c00) !important;
        }

        &.sError {
          background: linear-gradient(60deg, #ef5350, #e53935) !important;
        }

        &.sInfo {
          background: linear-gradient(60deg, #42a5f5, #1e88e5) !important;
        }
      }

      .sJobList {
        padding: 40px 10px 10px;
        font-size: 12px;
        div {
          cursor: pointer;
        }
      }
    }
  }

  .sCost {
    margin: 0 50px 50px;
  }
}
</style>
